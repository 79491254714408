const siteMetadata = {
    title: `Ebie Tattooer`,
    siteUrl: `https://ebietattooer.com`,
    capitalizeTitleOnHome: false,
    logo: `/images/Ebie_Tattooer_Logo.png`,
    icon: `/images/Ebie_Tattooer_Logo.png`,
    titleImage: `/images/Ebie_Tattooer_Bali.jpg`,
    ogImage: `/images/Ebie_Tattooer_Bali.jpg`,
    twoColumnWall: true,
    cookiePolicy: false,
    introTag: ``,
    description: `Ebie Tattooer has been professionally tattooing since 2014, Specialising in bold and solid blackwork. Now working at Burgeon Blue Tattoo, North Kuta, Bali.`,
    about:
        "Originally from Jakarta Indonesia. Ebie has been professionally tattooing since 2014. Specialising in bold and solid blackwork.",
    author: `@_akzhy`,
    blogItemsPerPage: 10,
    portfolioItemsPerPage: 10,
    darkmode: true,
    switchTheme: true,
    navLinks: [
        {
            name: "HOME",
            url: "/",
        },
        {
            name: "ABOUT",
            url: "/about/",
        },
        {
            name: "BLOG",
            url: "/blog/",
        },
        {
            name: "PORTFOLIO",
            url: "/portfolio/",
        },
        {
            name: "CONTACT",
            url: "/contact/",
        },
    ],
    footerLinks: [
        {
            name: "By TL",
            url: "https://travislord.xyz",
        },
        
    ],
    social: [
        {
            name: "Facebook",
            icon: "/images/Facebook_icon.png",
            url: "https://www.facebook.com/ebietattooer/",
        },
        {
            name: "Instagram",
            icon: "/images/Instagram_icon.png",
            url: "https://www.instagram.com/ebietattooer/",
        },
        
    ],
    contact: {
        // leave empty ('') or false to hide form
        api_url: "https://getform.io/f/f227a36e-096a-4c6a-9963-9f1918a85bb3",
        description: `Burgeon Blue Tattoo - Bali Tattoo Collective`,
        mail: "ebietattooer@gmail.com",
        phone: "",
        address: "Batu Belig, \nBadung Regency \nBali 80361",
    },
    disqus: "",
}

const beforeContactFormSubmit = data => {
    // Code 0 - success
    // Code 1 - Name
    // Code 2 - Email
    // Code 3 - Message
    // Code 4 - Other
    const errors = []

    if (data.name.trim().length < 2) {
        errors.push({
            code: 1,
            message: "Enter a name",
        })
    }

    if (!data.email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
        errors.push({
            code: 2,
            message: "Enter a valid email address",
        })
    }

    if (data.message.trim().length < 15) {
        errors.push({
            code: 3,
            message: "Enter a message with atleast 15 characters",
        })
    }

    if (errors.length > 0)
        return {
            result: false,
            errors: errors,
        }

    return {
        data: {
            name: data.name,
            email: data.email,
            message: data.message,
        },
        result: true,
    }
}

const contactFormSubmit = async (api, data) => {
    let res: any = await fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })

    res = await res.json()

    if (res.success) {
        return {
            result: true,
        }
    }
    return {
        result: false,
        ...res,
    }
}

const defaults = {
    disqus: null,
    twoColumnWall: true,
    darkmode: false,
    switchTheme: true,
    capitalizeTitleOnHome: true,
    cookiePolicy: false
}

Object.keys(defaults).forEach(item => {
    if (siteMetadata[item] === undefined) {
        siteMetadata[item] = defaults[item]
    }
})

export { siteMetadata, beforeContactFormSubmit, contactFormSubmit }
